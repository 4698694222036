<template>
  <v-skeleton-loader
    class="app-preview-file"
    type="image"
    :loading="!fileReady"
  >
    <v-carousel
      show-arrows-on-hover
      hide-delimiters
      class="app-preview-file--carousel"
    >
      <v-carousel-item
        v-for="item in previewFiles"
        :key="item.name"
        :src="item.type === 'image' ? item.src : null"
      >
        <embed
          v-if="item.type === 'pdf'"
          :src="item.src"
          width="100%"
          height="100%"
        />
      </v-carousel-item>
    </v-carousel>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: 'app-loading-linear',
  props: {
    /**
     * files to preciew
     */
    files: {
      type: Array,
      required: true
    },
    /**
     * file is ready
     */
    fileReady: {
      type: Boolean,
      default: true
    },
    /**
     * bind options for v-progress-linear
     */
    binds: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      previewFiles: [],
      loading: false
    }
  },
  watch: {
    files(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setPreview()
      }
    }
  },
  methods: {
    isImage(file) {
      return ['image/gif', 'image/jpeg', 'image/png'].includes(file.type)
    },
    isPdf(file) {
      return file.type === 'application/pdf'
    },
    async setPreview() {
      if (this.fileReady) {
        this.previewFiles = await Promise.all(
          this.files
            .filter((value) => this.isImage(value) || this.isPdf(value))
            .map(async (value) => ({
              type: this.isPdf(value) ? 'pdf' : 'image',
              file: value,
              src: URL.createObjectURL(value)
            }))
        )
      }
    }
  }
}
</script>

<style lang="scss">
.app-preview-file {
  border: 5px solid #616161;

  &--carousel {
    height: 100% !important;
  }
}
</style>
