<template>
  <v-card class="app-widget" :loading="loading">
    <template v-slot:progress>
      <v-progress-linear
        absolute
        indeterminate
        color="secondary"
        height="4"
        style="top: 48px"
      />
    </template>
    <v-toolbar dense color="primary white--text">
      <v-toolbar-title>
        <!-- @slot title -->
        <slot name="title">
          {{ title }}
        </slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="app-widget--action">
        <!-- @slot actions -->
        <slot name="actions" />
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <!-- @slot default -->
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'app-widget',
  props: {
    /**
     * App used for validate permission
     */
    app: {
      type: String,
      required: true
    },
    /**
     * Model used for validate permission
     */
    model: {
      type: String,
      required: true
    },
    /**
     * Widget title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Card loading
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Show add button
     */
    hideAdd: {
      type: Boolean,
      default: false
    },
    /**
     * Show delete button
     */
    hideDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    enableAdd() {
      return (
        !this.hideAdd &&
        this.permissions.includes(`${this.app}.add_${this.model}`)
      )
    },
    enableDelete() {
      return (
        !this.hideDelete &&
        this.permissions.includes(`${this.app}.delete_${this.model}`)
      )
    }
  },
  methods: {
    onAdd() {
      /**
       * Passthrough click add button
       * @type {Event}
       */
      this.$emit('add')
    },
    onDelete() {
      /**
       * Passthrough click delete button
       * @type {Event}
       */
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss">
.app-widget {
  &--action {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-btn {
      margin-left: 1rem;
      border-radius: 14px !important;
      height: 32px !important;
    }
  }
}
</style>
